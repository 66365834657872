@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

:root {
  --color1:#00979B; /* Teal */
  --color2:#73C1BB; /* Turquoise */
  --color3: #272E2E; /* Charcoal */
  --color4:#E5A26B; /* Salmon */
  --color5:#F1DC8E; /* Mustard */
  --color6: #fff; /* White */
}

* {
    box-sizing: border-box;
}

/* .dirHeight {
  max-height: 100 ;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
} */

html {
  scroll-behavior: smooth;
}

.ls-1{
  letter-spacing: 1px;
}
.ls-2{
  letter-spacing: 2px;
}
.ls-3{
  letter-spacing: 3px;
}

a, .link {
  color: var(--color1);
  text-decoration: none;
  transition: 1s;
}

.link:hover, a:hover{
  color:var(--color3)
}

.text-primary
{
  color: var(--color1)!important;
}

.border-primary
{
  border-color: var(--color1)!important;
}

.bg-primary {
  background-color: var(--color1)!important;
}
.bg-secondary {
  background-color: var(--color4)!important;
}

.btn-primary {
  background-color: var(--color1);
  outline-color: var(--color1);
  border-color: var(--color2);
  border: 0;
  transition: .5s;
  color: white;
}

.btn-secondary {
  background-color: var(--color4);
  outline-color: var(--color1);
  border-color: var(--color2);
  border: 0;
  transition: .5s
}

.alert-primary{
color:var(--color1);
background-color:rgb(241, 241, 241);
border-color:var(--color1);
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle{
  color:white;
  background-color: var(--color2);
  border-color: white;
}
.btn-primary:focus{
  color:var(--color1);
  background-color: var(--color5);
}
.btn-primary:hover{
  background-color: var(--color3);
}
.btn-primary:disabled{
  background-color: var(--color3);
}

.btn-light:hover {
 background-color: var(--color5);
}

/* btn link not working */
/* .btn {
  color:var(--color6);
  text-decoration: none;
} */


/* table stuff - old table styles -- delete*/
/* .react-bootstrap-table tr th {
  color: white;
  background-color: var(--color1);
}

.page-link {
  color:var(--color1);
  background-color: white;
  border-color: var(--color2);
}

.page-item.active .page-link {
  color: white;
  background-color: var(--color1);
  border-color: var(--color2);
}

.row-expansion-style {
  background-color: rgb(235, 235, 235);
}
.row-expansion-style a {
  color: var(--color1);
  text-decoration: none;
} */


body {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* height: 100vh; */
    /* margin: 0; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
    /* overflow: hidden; */
    /* background-color:#73C1BB; */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-color1 {
  background-color: var(--color1);
}

/* MENU MAIN & FOOTER*/
.menuMain {
  background-color: black;
  color: #fff;
}

.menuMain a {
  font-weight: bold;
  color: white;
  padding: 15px 10px 10px 10px;
  text-decoration: none;
  margin: auto;
  transition: 0.75s;
}

.menuMain a:hover{
  color:var(--color5)
}
.menuMain a:active,  .menuFooter a:active {
  color:var(--color2)
}


/* footer */
.menuFooter a {
  color:white;
  text-decoration: none;
  transition: 0.75s;
  font-size: 0.75rem;
  }

.menuFooter a:hover {
  color:var(--color5)
}

/* END MENUS AND FOOTERS */


/* map css */
.mapState {
  fill:var(--color1);
  stroke:#fff;
  stroke-width: 1.75;
  cursor: pointer;
  transition:.75s;
}

.mapState:hover {
  fill:var(--color2)
}
.mapState:active {
  fill:var(--color4)
}
.mapStateSelected {
  fill:var(--color4)
}

.mapLines {
  fill: none;
  stroke: #666;
  stroke-width: 3px;
}

.mapStateCode{
  pointer-events: none;
  font-weight: bold;
  fill:#fff;
}

.videoWrap{
  --video--width: 1920;
  --video--height: 1080;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  background: black;
}

.videoWrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}